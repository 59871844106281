import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61cadf04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "index index-effect" }
const _hoisted_2 = { class: "index-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Time = _resolveComponent("Time")!
  const _component_Search = _resolveComponent("Search")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Time),
      _createVNode(_component_Search)
    ]),
    _createVNode(_component_Footer)
  ]))
}